/* eslint-disable no-restricted-syntax */
import type { ViteSSGContext } from 'vite-ssg'

export type UserModule = (ctx: ViteSSGContext) => void

export interface Header {
  text: string
  value: string
  type?: ColumnType
  noSort?: boolean
  width?: string
  getString?: (props: any) => string
}

export type ColumnType = 'string' | 'datetime' | 'date' | 'boolean' | 'weekday'

// modal
export interface PushButtonTheme {
  name?: PushButtonThemeName
  primary: string
  active: string
  dark?: string
  disabled?: string
}
export type PushButtonThemes = PushButtonTheme[]
export type PushButtonThemeName =
  'white' |
  'dark' |
  'white-left' |
  'white-mid' |
  'white-right' |
  'text' |
  'indigo' |
  'indigo-light' |
  'indigo-dark' |
  'red' |
  'yellow' |
  'green' |
  'emerald' |
  'teal' |
  'cyan' |
  'blue' |
  'light-blue' |
  'purple' |
  'pink'
export type PushButtonState = 'active' | 'loading' | 'disabled'
export type PushButtonSize = 'xs' | 's' | 'm' | 'l' | 'xl'

export type ModalType = 'success' | 'info' | 'danger' | 'warning'

export interface ModalButton {
  label: string
  theme: PushButtonThemeName
  action: Function
}

export interface ModalProps {
  type?: ModalType
  title: string
  body?: string
  primary?: ModalButton
  secondary?: ModalButton
  error?: string
}

export interface TailvueModal {
  show(props: ModalProps | string): void
}
// modal end

// enums begin
export const enum EnumAuthRole {
  ROLE_ADMIN = 'Administrator',
  ROLE_APOKAMMER_ADMIN = 'Kammermitarbeiter (Administrator)',
  ROLE_APOKAMMER = 'Kammermitarbeiter',
  ROLE_APOLEITER = 'Apothekenleiter',
  ROLE_EXTERN = 'Externer Nutzer',
}

export enum EnumRegionFilterIter {
  ALL = 'Alle anzeigen',
  MV = 'Mecklenburg-Vorpommern',
  SH = 'Schleswig-Holstein',
  NDS = 'Niedersachsen',
  HH = 'Hamburg',
  HB = 'Bremen',
  NRW_WL_LIPPE = 'Westfalen-Lippe',
  NRW_NR = 'Nordrhein',
  B = 'Berlin',
  TH = 'Thüringen',
  BAY = 'Bayern',
  RLP = 'Rheinland-Pfalz',
  BW = 'Baden-Württemberg',
  SACHSEN = 'Sachsen',
  SACHSENANHALT = 'Sachsen-Anhalt',
  BB = 'Brandenburg',
  HS = 'Hessen',
  SAR = 'Saarland',
}

export enum EnumRegionIter {
  MV = 'Mecklenburg-Vorpommern',
  SH = 'Schleswig-Holstein',
  NDS = 'Niedersachsen',
  HH = 'Hamburg',
  HB = 'Bremen',
  NRW_WL_LIPPE = 'Westfalen-Lippe',
  NRW_NR = 'Nordrhein',
  B = 'Berlin',
  TH = 'Thüringen',
  BAY = 'Bayern',
  RLP = 'Rheinland-Pfalz',
  BW = 'Baden-Württemberg',
  SACHSEN = 'Sachsen',
  SACHSENANHALT = 'Sachsen-Anhalt',
  BB = 'Brandenburg',
  HS = 'Hessen',
  SAR = 'Saarland',
}

export const enum EnumRegion {
  MV = 'Mecklenburg-Vorpommern',
  SH = 'Schleswig-Holstein',
  NDS = 'Niedersachsen',
  HH = 'Hamburg',
  HB = 'Bremen',
  NRW_WL_LIPPE = 'Westfalen-Lippe',
  NRW_NR = 'Nordrhein',
  B = 'Berlin',
  TH = 'Thüringen',
  BAY = 'Bayern',
  RLP = 'Rheinland-Pfalz',
  BW = 'Baden-Württemberg',
  SACHSEN = 'Sachsen',
  SACHSENANHALT = 'Sachsen-Anhalt',
  BB = 'Brandenburg',
  HS = 'Hessen',
  SAR = 'Saarland',
}

export const enum EnumApothekeSuchtyp {
  PLZ_ORT = 'PLZ_ORT',
  IDS = 'IDS',
}

export const enum EnumNotdienstKriterium {
  Wochenende = 'Wochenende',
  Samstag = 'Samstag',
  Sonntag = 'Sonntag',
  Montag = 'Montag',
  Dienstag = 'Dienstag',
  Mittwoch = 'Mittwoch',
  Donnerstag = 'Donnerstag',
  Freitag = 'Freitag',
  Januar = 'Januar',
  Februar = 'Februar',
  März = 'März',
  April = 'April',
  Mai = 'Mai',
  Juni = 'Juni',
  Juli = 'Juli',
  August = 'August',
  September = 'September',
  Oktober = 'Oktober',
  November = 'November',
  Dezember = 'Dezember',
  Feiertag = 'Feiertag',
  Gesamt = 'Gesamt',
}

export const enum EnumNotdienstAenderungAntragTyp {
  ALLE = 'Alle anzeigen',
  TAUSCH = 'TAUSCH',
  ZUSATZDIENST = 'ZUSATZDIENST',
  ZUSATZDIENST_OEFF = 'ZUSATZDIENST (Eröffnung)',
  UEBERNAHME = 'UEBERNAHME',
  UEBERNAHME_SCHL = 'UEBERNAHME (Schließung)',
  UEBERNAHME_AUSN = 'UEBERNAHME (Ausnahme)',
  BEFREIUNG = 'BEFREIUNG',
  BEFREIUNG_VERZ = 'BEFREIUNG (Verzahnung)',
  BEFREIUNG_SCHL = 'BEFREIUNG (Schließung)',
}
// enums end
